import "./global.css"
import "./normalize.css"
import React from 'react'

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {


    // Scroll to top of the page when navigating to a new page
    window.scrollTo(0, 0)

    // Return true to update scroll position for new page
    return true
}